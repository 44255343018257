import './css/styles.css';
import { Link } from "react-router-dom";
import { FaInstagram, FaTwitter, FaSpotify } from 'react-icons/fa';
import { AiOutlineMail } from "react-icons/ai";


import logo from './assets/img/logo.png';
import dossier from './assets/dossier.pdf';
import tour1 from './assets/img/tour1.jpg';
import tour2 from './assets/img/tour2.jpg';
import tour3 from './assets/img/tour3.png';





export default function Home() {
    return (
        <body id="page-top">

            <nav class="navbar navbar-expand-lg fixed-top d-none d-xl-block" id="mainNav">

                <div class="d-flex justify-content-between w-100 ">
                    <div class="p-2 align-self-center">
                        <div class="caja"></div>
                    </div>
                    <div class="p-2 " >
                        <ul class="navbar-nav ms-auto flex-cloumn flex-md-row ">
                            <li class="nav-item "><a class="nav-link text-white" href="#bio">Nosotros</a></li>
                            <li class="nav-item"><a class="nav-link text-white" href="#info">Noticias</a></li>
                            <li class="nav-item " height="100%" >
                                <img src={logo} class="align-middle mt-3" alt="Logo Icon" width="50" height="50" />
                            </li>
                            <li class="nav-item"><a class="nav-link text-white" href="#tour">Conciertos</a></li>

                            <li class="nav-item"><a class="nav-link text-white" href="#signup">Contacto</a></li>
                        </ul>
                    </div>
                    <div class="p-2 pb-5 align-self-center">
                        <Link to="https://www.instagram.com/sindromeband/"><FaInstagram className="text-white me-3 " href="" /></Link>
                        <Link to="https://twitter.com/SindromeBanda"><FaTwitter className="text-white me-3" href="" /></Link>
                        <Link to="https://open.spotify.com/artist/604TcKJbZsg9My7Xyx2ZYY?si=1I_zHl-eTcmTFTgGIS8ZuA"><FaSpotify className="text-white me-3" href="" /></Link>
                    </div>
                </div>
            </nav>

            <header class="masthead">
                <div class="container d-flex  position-absolute bottom-0 start-50 translate-middle-x justify-content-center">

                    <iframe styles="border-radius:12px" src="https://open.spotify.com/embed/album/7ufumqHC4DFDwtMAvtH65x?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>


                </div>

            </header>

            <section class="bio-section text-center" id="bio">
                <div className="container">
                    <div class="badge bg-gray text-wrap"><h2 class="text-primary mb-4 d-none d-lg-block ">¿Qué es Síndrome?</h2>
                        <h6 class="text-primary  d-none d-lg-block">
                            El rock progresivo y el pop es la base musical de "Síndrome" una banda onubense que con gran versatilidad, intenta dar una versión modernizada de ese clásico genero de los setenta. Con mucha energía reivindica que se puede ser sofisticado a la par que "cañero".
                            Formado en Huelva, Andalucía, en el año 2020 en un principio por Álvaro Rivero a la guitarra y Francisco Hermosín como cantante, el proyecto musical "Síndrome" actualmente está formado por, Alejandro Minchón al bajo, Jose Lacruz a la otra guitarra y segunda voz y Abraham Leñero a la batería. No conforme con esto, la banda también cuenta con instrumentistas como Ignacio Arévalo, al saxofon o Fernando Alejandre, pianista, entre otros.
                        </h6></div>
                    <div class="card bg-white bg-opacity-75 d-lg-none"  >
                        <div class="card-header">
                            <h2 class="text-primary">¿Qué es Síndrome?</h2>
                        </div>
                        <div class="card-body ">
                            <blockquote class="blockquote mb-0">
                                <p>   El rock progresivo y el pop es la base musical de "Síndrome" una banda onubense que con gran versatilidad, intenta dar una versión modernizada de ese clásico genero de los setenta. Con mucha energía reivindica que se puede ser sofisticado a la par que "cañero".</p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

            <section class="info-section text-center" id="info" >

                {/* <div className="container">
                    <div class="badge text-wrap"><h2 class="text-primary">Noticias</h2></div>
                </div> */}
                <div class="container d-flex ">
                    <div id="carouselExampleControls" class="carousel " data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="card bg-white bg-opacity-75">
                                    <div class="card-header">
                                        Disco ya disponible
                                    </div>
                                    <div class="card-body">
                                        <blockquote class="blockquote mb-0">
                                            <p>Ya está disponible el disco de Vísperas De Un Sueño, hemos remado a contracorriente durante estos últimos 3 años para daros este álbum que esperamos que lo disfrutéis</p>
                                        </blockquote>

                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item ">
                                <div class="card bg-white bg-opacity-75"  >
                                    <div class="card-header">
                                        Nuevo single disponible: "A Solas"
                                    </div>
                                    <div class="card-body ">
                                        <blockquote class="blockquote mb-0">
                                            <p>En "A Solas", la letra versa sobre el crecimiento y el pasar del tiempo durante la adolescencia, además de contar con un sonido más duro y ciertamente épico.
                                                Mientras que en "Bailando Los Dos" la letra habla sobre un temprano amorío, viendo esto en su música, la cual es una balada con secciones melancólicas e introspectivas.</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>
            </section>

            <section class="projects-section" id="projects">
                <div className="container">
                    <div className="row">
                        <div className="container col-sm-6">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/557onNTFHWw" title=" ¿QUIÉN LLORARÁ?"></iframe>
                        </div>
                        <div className="container col-sm-6">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/videoseries?si=9QJl03kyLoLIADH2&amp;list=OLAK5uy_mJF0VsHGc3V61j-ctx9aGMKyfZ4jsxEbY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        </div>
                    </div>
                </div>

            </section>



            <section class="tour-section" id="tour">
                {/* <div className="container">
                    <div className="row h">
                        <div className="container col-md bg-white bg-opacity-50 overflow-auto">
                            <div class="text-center border-bottom border-dark">
                                <h2 class="text-black  ">Fechas</h2>
                            </div>
                            <div className=" overflow-auto">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <div className="row">
                                            <div className="col-4 overflow-hidden">
                                                <h2>03/06/23</h2>
                                            </div>
                                            <div className="col-8 ">
                                                <h3>Musicarte - El matadero</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div className="row">
                                            <div className="col-4 overflow-hidden">
                                                <h2>28/04/23</h2>
                                            </div>
                                            <div className="col-8 ">
                                                <h3>Matadero Rock - Escuela de arte</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div className="row">
                                            <div className="col-4 overflow-hidden">
                                                <h2>17/12/22</h2>
                                            </div>
                                            <div className="col-8">
                                                <h3>Bar 1900</h3>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="container" >
                    <div class=" text-center">
                        <h2 class=" text-white  pt-4 mb-4">Fechas reseñables</h2>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col ">
                            <img src={tour1} class="align-middle w-75 h-75" alt="Logo Icon"  />
                         </div>
                        <div className="d-flex justify-content-center col">
                            <img src={tour2} class="align-middle w-75 h-75" alt="Logo Icon"/>
                        </div>
                        <div className="d-flex justify-content-center col">
                            <img src={tour3} class="align-middle w-75 h-75" alt="Logo Icon" />
                        </div>
                    </div>

                </div>

            </section>


            <section class="ultima-section " id="signup">

                <div className="row ">
                    <div className="container  bg-opacity-75  my-4 col-8 d-none d-lg-block">
                        <div class=" p-2 text-center  ">
                            <h2 class=" text-white mb-4 mt-4 ">Contacto</h2>
                        </div>
                        <div class="text-center">
                            <Link to="mailto:sindromemusica@gmail.com"><AiOutlineMail className="me-3" href="" />sindromemusica@gmail.com</Link><br />

                            <Link to="https://www.instagram.com/sindromeband/" ><FaInstagram className=" me-3 " href="" /> @sindromeband</Link><br />

                            <Link to="https://twitter.com/SindromeBanda"  ><FaTwitter className="me-3" href="" /> @SindromeBanda</Link><br />
                            <a href={dossier} download="dossier.pdf">
                                Descarga nuestro dossier
                            </a>


                        </div>

                    </div>

                </div>
                <div className="container bg-white bg-opacity-50 mt-4 d-lg-none" >
                    <div class="text-center">
                        <h2 class="text-black  ">Contacto</h2>
                    </div>
                    <div>
                        <Link to="mailto:sindromemusica@gmail.com"><AiOutlineMail className="text-black  me-3" href="" />sindromemusica@gmail.com</Link><br />
                        <Link to="https://www.instagram.com/sindromeband/"><FaInstagram className="text-black me-3 " href="" /> @sindromeband</Link><br />
                        <Link to="https://twitter.com/SindromeBanda"><FaTwitter className="text-black m
                            e-3" href="" /> @SindromeBanda</Link><br />
                        <a href={dossier} download="dossier.pdf">
                            Descarga nuestro dossier
                        </a>
                    </div>
                </div>
            </section>


            <footer class="footer bg-black small text-center text-white-50">
                <div class="p-2 align-self-center">
                    <Link to="https://www.instagram.com/sindromeband/"><FaInstagram className="text-white me-3" href="" /></Link>
                    <Link to="https://twitter.com/SindromeBanda"><FaTwitter className="text-white me-3" href="" /></Link>
                    <Link to="https://open.spotify.com/artist/604TcKJbZsg9My7Xyx2ZYY?si=1I_zHl-eTcmTFTgGIS8ZuA"><FaSpotify className="text-white me-3" href="" /></Link>

                </div>
                <div class="container px-4 px-lg-5">Desarrollado por Alejandro Minchón</div>
            </footer>

            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>

            <script src="js/scripts.js"></script>
            <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
        </body>
    )
}